<template>
  <BaseDialog
    :show="true"
    title="提醒"
    width="586px"
    @confirm="$emit('confirm')"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <div class="pb-[40px]">
      請問確定要儲存變更嗎？ {{ displayData.scope }}的訂單將會受到影響。
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { customOrderStatusModulesConfig } from '@/config/customOrderStatus'
import { get } from 'lodash'

export default defineComponent({
  name: 'SaveStatusWarnModal',
  components: { BaseDialog },
  props: {
    scope: String,
  },
  setup (props) {
    const displayData = computed(() => {
      return {
        scope: get(customOrderStatusModulesConfig, `${props.scope}.label`),
      }
    })
    return { displayData }
  },
})
</script>

<style lang="postcss" scoped>

</style>
