<template>
  <div class="side-highlight-container">
    <p class="card-title">流程涵蓋狀態</p>

    <div class="flex flex-col gap-[20px]">
      <div class="flex items-center" style="gap: 8px">
        狀態設定
        <TipInfo width="200" :size="16">
          <div>說明：請為您的自定義流程新增狀態，用以描述訂單的最新動態，如：待叫貨、運送中、已到貨。</div>
        </TipInfo>
      </div>
      <ElInputWrapper class="w-[100px]">
        <el-button type="primary" @click="onAddStatus">新增狀態</el-button>
      </ElInputWrapper>
      <p v-if="!syncForm.nodes.length" class="text-gray-60 font-medium text-normal">尚未有資訊，請先新增狀態</p>

      <TransitionGroup name="list" tag="ul" class="flex flex-col gap-[20px]">
        <StatusEditBlock
          v-for="(statusBlock, idx) in syncForm.nodes"
          :key="statusBlock.id"
          :data.sync="syncForm.nodes[idx]"
          :configData="configData"
          :appointedStatus="appointedStatus"
          :displayStatus="displayStatus"
          @appointed="onStatusAppointed"
          @removeAppointed="onStatusRemoveAppointed"
          @delete="onStatusDelete(idx, $event)"
          @moveUp="onMoveOrder(idx, 'up')"
          @moveDown="onMoveOrder(idx, 'down')"
        />
      </TransitionGroup>

      <EditStatusNameModal
        v-if="modal.createStatus"
        mode="create"
        @confirm="createStatus"
        @close="modal.createStatus = false"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref, set, reactive, onBeforeMount } from 'vue'
import { useVModel } from '@vueuse/core'
import TipInfo from '@/components/TipInfo.vue'
import StatusEditBlock from './StatusEditBlock.vue'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import EditStatusNameModal from './EditStatusNameModal.vue'
import { useRoute } from 'vue-router/composables'
import { moveArrayRefItem } from '@/utils/array'
import { customAlphabet } from 'nanoid'
import { get, filter, map } from 'lodash'

export default defineComponent({
  name: 'CustomOrderStatusEditBlock',
  components: { TipInfo, StatusEditBlock, ElInputWrapper, EditStatusNameModal },
  props: {
    form: { type: Object, default: () => ({}) },
    formRules: { type: Object, default: () => ({}) },
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const syncForm = useVModel(props, 'form', emit)
    const refreshArea = ref(false)
    const route = useRoute()
    const targetId = computed(() => get(route, 'params.id'))

    // 用於響應式關聯顯示用，因為 status 名稱可以被改變，有些地方被關連的 status 需要同時更新顯示
    const displayStatus = computed(() => {
      return map(get(syncForm.value, 'nodes'), item => {
        return {
          id: item.id,
          name: item.newName || item.name,
          isRemoved: Boolean(item.moveTo),
        }
      })
    })
    const appointedStatus = ref([])
    const modal = reactive({
      createStatus: false,
    })
    const onAddStatus = () => {
      modal.createStatus = true
    }
    const onStatusDelete = (idx) => {
      syncForm.value.nodes.splice(idx, 1)
    }
    const onMoveOrder = (idx, direction) => {
      moveArrayRefItem(syncForm.value.nodes, idx, direction)
    }
    const onStatusAppointed = (statusId) => {
      appointedStatus.value.push(statusId)
    }
    const onStatusRemoveAppointed = (statusId) => {
      console.log('onStatusRemoveAppointed', statusId)
      appointedStatus.value = filter(appointedStatus.value, i => i !== statusId)
    }
    const createStatus = (name) => {
      const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      const nanoid = customAlphabet(alphabet, 12)

      syncForm.value.nodes.push({
        name,
        id: nanoid(),
        isNew: true,
      })
    }

    return {
      syncForm,
      onAddStatus,
      createStatus,
      onStatusDelete,
      modal,
      moveArrayRefItem,
      onStatusAppointed,
      onStatusRemoveAppointed,
      appointedStatus,
      displayStatus,
      onMoveOrder,
      refreshArea,
    }
  },
})
</script>

<style scoped lang="postcss">
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
</style>
