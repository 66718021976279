export const moveArrayRefItem = (
  arrayRef,
  index,
  direction,
) => {
  if (index === 0 && direction === 'up') return
  if (index === (arrayRef.length - 1) && direction === 'down') return
  const item = arrayRef.splice(index, 1)[0]

  if (direction === 'up' && index > 0) {
    arrayRef.splice(index - 1, 0, item)
  }

  if (direction === 'down' && index < arrayRef.length) {
    arrayRef.splice(index + 1, 0, item)
  }
}
