<template>
  <BaseDialog
    :show="true"
    title="提醒"
    hideCancel
    width="586px"
    @confirm="$emit('close')"
    @close="$emit('close')"
  >
    <div class="pb-[40px]">
      請先至少新增一個狀態。
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'

export default defineComponent({
  name: 'EmptyStatusWarnModal',
  components: { BaseDialog },
})
</script>

<style lang="postcss" scoped>

</style>
