<template>
  <div class="status-edit-block" :class="{locked: blockState.locked}">
    <header class="block-header">
      <div v-if="targetId" class="status-label">
        <p>{{ syncData.name }}</p>
        <p v-if="syncData.newName || syncData.moveTo">→</p>
        <p v-if="syncData.newName">{{ syncData.newName }}</p>
        <p v-if="syncData.moveTo">{{ syncData.moveTo.id === 'none' ? '–' : syncData.moveTo.name }}</p>
        <p v-if="isAvailableFirstStatus" class="text-gray-60">(預設)</p>
      </div>
      <div v-else class="status-label">
        <p>{{ syncData.name }}</p>
        <p v-if="isAvailableFirstStatus" class="text-gray-60">(預設)</p>
      </div>
      <div class="flex items-center gap-[16px]">
        <div>
          <el-button v-if="blockState.canDelete" class="underline" type="text" :disabled="isAppointedStatus" @click="onDelete">刪除</el-button>
          <el-button v-if="blockState.canEdit" class="underline" type="text" @click="onEdit">編輯</el-button>
          <el-button v-if="blockState.cancelDelete" class="underline" type="text" @click="onCancelDelete">復原</el-button>
          <el-button v-if="blockState.cancelEdit" class="underline" type="text" @click="onCancelEdit">重新編輯</el-button>
        </div>
        <div class="flex items-center gap-[2px]">
          <div class="select-none cursor-pointer" :class="{'disabled-icon': isLastStatus}" @click="$emit('moveDown')">
            <MaterialIcon :color="isLastStatus ? 'var(--gray-40)' : 'var(--primary-100)'">arrow_downward</MaterialIcon>
          </div>
          <div class="select-none cursor-pointer" :class="{'disabled-icon': isFirstStatus}" @click="$emit('moveUp')">
            <MaterialIcon :color="isFirstStatus ? 'var(--gray-40)' : 'var(--primary-100)'">arrow_upward</MaterialIcon>
          </div>
        </div>
      </div>
    </header>

    <div v-if="targetId" class="description-area">
      <div>
        <ul class="list-disc">
          <li v-if="blockState.cancelDelete" class="text-danger">
            已選擇刪除，點選儲存後，將有 {{ blockState.effectCount }}  筆訂單狀態由 [{{ syncData.name }}] 異動為 [{{ findStatusName(syncData.moveTo?.id) }}] 。
          </li>
          <li v-if="isAppointedStatus" class="text-gray-60">已受其他狀態指定，如需刪除，請先點選儲存後再進行，或是移除指定。</li>
          <li v-if="blockState.cancelEdit" class="text-gray-60">已進行編輯，點選儲存後，將有 {{ blockState.effectCount }} 筆訂單狀態由 [{{ syncData.name }}] 異動為 [{{ syncData.newName }}] 。</li>
        </ul>
      </div>
    </div>

    <EditStatusNameModal
      v-if="modal.editName"
      :data="syncData"
      mode="edit"
      @confirm="confirmEdit"
      @close="modal.editName = false"
    />
    <DeleteStatusModal
      v-if="modal.deleteStatus"
      :data="syncData"
      :configData="configData"
      :displayStatus="displayStatus"
      @confirm="confirmDelete"
      @close="modal.deleteStatus = false"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive, set, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import EditStatusNameModal from './EditStatusNameModal.vue'
import DeleteStatusModal from './DeleteStatusModal.vue'
import { useRoute } from 'vue-router/composables'
import { get, includes, find, findIndex, filter } from 'lodash'
import { CalcCustomFlowNodeCount } from '@/api/customFlow'
import { useShop } from '@/use/shop'
import MaterialIcon from '@/components/MaterialIcon.vue'

export default defineComponent({
  name: 'StatusEditBlock',
  components: { EditStatusNameModal, DeleteStatusModal, MaterialIcon },
  props: {
    data: { type: Object, default: () => ({}) },
    formRules: { type: Object, default: () => ({}) },
    configData: { type: Object, default: () => ({}) },
    appointedStatus: { type: Array, default: () => [] },
    displayStatus: { type: Array, default: () => [] },
  },
  setup (props, { emit }) {
    const syncData = useVModel(props, 'data', emit)
    const route = useRoute()
    const { shopId } = useShop()
    const targetId = computed(() => get(route, 'params.id'))
    const isAppointedStatus = computed(() => includes(props.appointedStatus, get(props.data, 'id')))
    const isFirstStatus = computed(() => findIndex(props.displayStatus, { id: props.data.id }) === 0)
    const isAvailableFirstStatus = computed(() => findIndex(filter(props.displayStatus, i => !i.isRemoved), { id: props.data.id }) === 0)
    const isLastStatus = computed(() => findIndex(props.displayStatus, { id: props.data.id }) === (props.displayStatus.length - 1))
    const modal = reactive({
      editName: false,
      deleteStatus: false,
      saveWarn: false,
    })
    const blockState = reactive({
      locked: false,
      canEdit: true, // 編輯
      canDelete: true, // 刪除
      cancelDelete: false, // 復原
      cancelEdit: false, // 重新編輯
      effectCount: '–',
    })
    const calcSideEffect = async () => {
      const [res, err] = await CalcCustomFlowNodeCount({
        shopId: shopId.value,
        id: props.configData.id,
        nodeId: props.data.id,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      blockState.effectCount = res.count
    }
    const findStatusName = (statusId) => {
      const target = find(props.displayStatus, { id: statusId })
      if (target) return get(target, 'name')
      return '–'
    }

    const onDelete = () => {
      if (!targetId.value) {
        emit('delete')
        return
      }
      modal.deleteStatus = true
    }
    const confirmDelete = (pointStatus) => {
      if (pointStatus.id !== 'none') emit('appointed', pointStatus.id)
      set(syncData.value, 'moveTo', pointStatus)
      blockState.locked = true
      blockState.canEdit = false
      blockState.canDelete = false
      blockState.cancelDelete = true
      blockState.canEdit = false
      blockState.effectCount = '[計算中...]'
      calcSideEffect()
    }
    const onEdit = () => {
      modal.editName = true
    }
    const confirmEdit = (newName) => {
      // if (newName === syncData.value.name) return
      if (targetId.value) {
        set(syncData.value, 'newName', newName)
        blockState.locked = false
        blockState.canEdit = false
        blockState.canDelete = false
        blockState.cancelDelete = false
        blockState.cancelEdit = true
        blockState.effectCount = '[計算中...]'
        calcSideEffect()
      } else syncData.value.name = newName
    }
    const onCancelDelete = () => {
      if (syncData.value.moveTo?.id) emit('removeAppointed', syncData.value.moveTo.id)
      syncData.value.moveTo = null
      resetBlockState()
    }
    const onCancelEdit = () => {
      if (targetId.value) {
        syncData.value.newName = null
        resetBlockState()
      } else syncData.value.name = null
    }

    const resetBlockState = () => {
      blockState.locked = false
      blockState.canEdit = true
      blockState.canDelete = true
      blockState.cancelDelete = false
      blockState.cancelEdit = false
      blockState.effectCount = '–'
    }
    return {
      syncData,
      onDelete,
      onEdit,
      onCancelDelete,
      onCancelEdit,
      modal,
      blockState,
      targetId,
      confirmDelete,
      confirmEdit,
      isAppointedStatus,
      findStatusName,
      isFirstStatus,
      isAvailableFirstStatus,
      isLastStatus,
    }
  },
})
</script>

<style scoped lang="postcss">
.status-edit-block {
  @apply w-[560px];
  @apply border border-solid border-primary-100;
  @apply py-[20px] px-[16px];
  @apply rounded-[6px];
}

.block-header {
  @apply flex items-center justify-between;
}

.status-label {
  @apply font-medium text-normal;
  @apply flex items-center gap-[2px];
}

.description-area {
  @apply text-sub pl-[24px];
}

.locked {
  @apply border-[#F5F7FA] bg-[#F5F7FA];
}

.disabled-icon {
  @apply pointer-events-none cursor-not-allowed;
}
</style>
